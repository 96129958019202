import { Parallax } from "react-parallax"
import containers from "../assets/containers.jpg"
import city from "../assets/city.jpg"
import chemical from "../assets/chemical.jpg"
import emergency from "../assets/emergency.jpg"
import industrial from "../assets/industrial.jpg"
import { Box, Button, Typography, useMediaQuery } from "@mui/material"
import theme from "../theme"
import { useNavigate } from "react-router-dom"
import locistic from "../assets/logistic.jpeg";

export const Home = () => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery(theme().breakpoints.down(1192));
    return (
        <Box>
            <Parallax bgImage={containers} strength={500}>
                <Box style={{ height: 500, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "10px" }}>
                    <Typography variant="h6" color="#FFF" fontFamily='"Andada", lato'>GET IT DONE WITH</Typography>
                    <Typography textAlign="center" variant="h2" color="#FFF" fontFamily='"Andada", lato'>ONE SUPPLIER FOR ALL <br /> SOLUTIONS</Typography>
                    <Button onClick={() => navigate("/about")} style={{ borderRadius: "25px", padding: "10px 15px" }} variant="contained">Learn more</Button>
                </Box>
            </Parallax >
            <Box style={{ height: "500px", backgroundColor: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "10px" }}>
                <Typography variant="h6" color="rgba(1,22,39,0.61)" fontFamily='"Andada", lato'>ABOUT US</Typography>
                <Typography textAlign="center" variant="h6" color="rgba(1,22,39,0.61)" fontFamily='"Andada", lato'>We understand the needs of the industry and are ready to submit relevant products <br /> and solutions to serve them all.</Typography>
                <Button style={{ borderRadius: "25px", padding: "10px 15px" }} onClick={() => navigate("/about")} variant="contained">Learn more</Button>
            </Box>
            <Parallax bgImage={city} strength={500}>
                <Box style={{ height: 600, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "10px" }}>
                    <Box style={{ minHeight: "60%", width: "80%", backgroundColor: "#FFF", opacity: "0.9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography width="60%" fontSize="24px" textAlign="center" color="rgba(1,22,39,0.75)" fontFamily='"Andada", lato'>We are Your partner in assuring your products and processes are strong, viable and prepared to meet the market demands wherever you do business and wherever we are.</Typography>
                    </Box>
                </Box>
            </Parallax>
            <Box style={{ minHeight: "500px", backgroundColor: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "10px", padding: "50px" }}>
                <Typography variant="h6" color="rgba(1,22,39,0.61)" fontFamily='"Andada", lato'>OUR CONTRIBUTIONS</Typography>
                <Typography mb={3} fontWeight={600} textAlign="center" variant="h4" color="rgba(1,22,39,0.61)" fontFamily='"Andada", lato'>PRODUCT CATEGORY</Typography>
                <Box display="flex" justifyItems="center" gap="50px" flexDirection={isMobile ? "column" : "row"}>
                    <Box onClick={() => navigate("/logistics")} style={{ cursor: "pointer" }} width={300} display="flex" flexDirection="column" alignItems="center" gap="20px">
                        <img style={{ width: "100%", height: "215px", objectFit: "cover" }} src={locistic} alt="emergency" />
                        <Typography fontFamily='"Andada", lato' letterSpacing="3px" color="rgba(1,22,39,0.75)" fontSize="16px" fontWeight={600} textAlign="center">LOGISTICS</Typography>
                    </Box>
                    <Box onClick={() => navigate("/products/equipment")} style={{ cursor: "pointer" }} width={300} display="flex" flexDirection="column" alignItems="center" gap="20px">
                        <img style={{ width: "100%", height: "215px", objectFit: "cover" }} src={industrial} alt="indurtrial" />
                        <Typography fontFamily='"Andada", lato' letterSpacing="3px" color="rgba(1,22,39,0.75)" fontSize="16px" fontWeight={600} textAlign="center">INDUSTRIAL EQUIPMNET AND SPARE PARTS</Typography>
                    </Box>
                    <Box onClick={() => navigate("/products/chemicals")} style={{ cursor: "pointer" }} width={300} display="flex" flexDirection="column" alignItems="center" gap="20px">
                        <img style={{ width: "100%", height: "215px", objectFit: "cover" }} src={chemical} alt="chemical" />
                        <Typography fontFamily='"Andada", lato' letterSpacing="3px" color="rgba(1,22,39,0.75)" fontSize="16px" fontWeight={600} textAlign="center">CHEMICAL</Typography>
                    </Box>
                    <Box onClick={() => navigate("/products/emergency")} style={{ cursor: "pointer" }} width={300} display="flex" flexDirection="column" alignItems="center" gap="20px">
                        <img style={{ width: "100%", height: "215px", objectFit: "cover" }} src={emergency} alt="emergency" />
                        <Typography fontFamily='"Andada", lato' letterSpacing="3px" color="rgba(1,22,39,0.75)" fontSize="16px" fontWeight={600} textAlign="center">EMERGENCY EQUIPMENT, FIRST AID AND PPE</Typography>
                    </Box>

                </Box>
            </Box>
            <Box style={{ padding: "20px", minHeight: "200px", backgroundColor: "#f9f8f8", display: "flex", justifyContent: "center", alignItems: "center", gap: "20px", flexDirection: isMobile ? "column" : "row" }}>
                <Typography textAlign={isMobile ? "center" : "start"} width={isMobile ? "80%" : "60%"} fontSize="30px" fontWeight={600} color="#000" >Need help with easier industrial solutions? We are experts!</Typography>
                <Button style={{ borderRadius: "25px", padding: "10px 15px" }} onClick={() => navigate("/contact")} variant="contained">Learn more</Button>
            </Box>
        </Box >
    )

}