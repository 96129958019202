import EmailIcon from '@mui/icons-material/Email'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Box, Typography, styled, useMediaQuery } from "@mui/material"
import { Parallax } from "react-parallax"
import contact from "../assets/contact.jpg"
import theme from "../theme"

const Root = styled(Box)(() => ({
    "& .react-parallax-bgimage": {
        filter: "brightness(0.5) !important"
    }
}));

export const Contact = () => {
    const isMobile = useMediaQuery(theme().breakpoints.down(1192));
    return (
        <Root>
            <Parallax bgImage={contact} strength={500}>
                <Box style={{ height: isMobile ? 250 : 500, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "10px" }}>
                    <Typography fontWeight={600} variant={isMobile ? "h4" : "h2"} color="#FFF" >CONTACT</Typography>
                </Box>
            </Parallax>
            <Box style={{ backgroundColor: "#FFF", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", gap: "10px", padding: isMobile ? "50px" : "50px 150px" }}>
                <Typography fontWeight={600} variant="h4" >Contact us</Typography>
                <Box mt={3} display="flex" flexDirection="column" gap={2}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <LocationOnIcon /> <Typography fontSize="20px">49 Füzuli Küçəsi, SKS Plaza, Bakı</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                        <EmailIcon /> <Typography fontSize="20px">info@imexcorp.co</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                        <LocalPhoneIcon /> <Typography fontSize="20px">+994515931054</Typography>
                    </Box>
                </Box>
            </Box>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3039.405275644659!2d49.83598647594371!3d40.37770957144576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307da4624589fd%3A0xddc3a7d17066f74e!2zU0tTIFBMQVpBLCA0OSBGw7x6dWxpIEvDvMOnyZlzaSwgQmFrxLE!5e0!3m2!1str!2saz!4v1686779944533!5m2!1str!2saz" width="100%" height="600" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Root>
    )

}