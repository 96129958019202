import { green, red } from "@mui/material/colors";
import { createTheme, darken } from "@mui/material/styles";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({});

const theme = () => {
  return createTheme({
    spacing: 8,
    breakpoints,
    shape: { borderRadius: 10 },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            fontSize: 16,
            [breakpoints.down("sm")]: {
              fontSize: 15,
            },
          },
          body: {
            "&::-webkit-scrollbar": {
              width: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#8a8787",
              borderRadius: "8px",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            textTransform: "none",
            padding: "8px 32px",
            boxShadow: "none",
          },
          containedSecondary: {
            color: "#fff",
            backgroundColor: "#5D5DFF",
            "&:hover": {
              backgroundColor: darken("#5D5DFF", 0.1),
            },
          },
          containedPrimary: {
            color: "#fff",
            backgroundColor: "#008C64",
            "&:hover": {
              backgroundColor: darken("#008C64", 0.1),
            },
          },
          containedInherit: {
            color: "#FFF",
            backgroundColor: "#B5B6BA",
            "&:hover": {
              backgroundColor: darken("#B5B6BA", 0.1),
            },
          },
          textSecondary: {
            color: "#7D7DFF",
            fontSize: "15px",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
            borderRadius: 6,
            boxShadow: "none",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            ".MuiAutocomplete-inputRoot": {
              paddingBottom: "20px",
            },
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            "& .MuiTableCell-root": {
              fontSize: "0.875rem",
              fontWeight: 500,
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              whiteSpace: "nowrap",
              color: "#6C6D75",
              padding: "18px 0px !important",
              "&:first-child": {
                borderTopLeftRadius: "0.625rem",
                borderBottomLeftRadius: "0.625rem",
              },
              "&:last-child": {
                borderTopRightRadius: "0.625rem",
                borderBottomRightRadius: "0.625rem",
              },
            },
            "& .MuiTableRow-root": {
              "& th": {
                padding: "14px 8px !important",
              },
            },
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            "& .MuiTableCell-root": {
              maxWidth: "300px",
              fontWeight: 400,
              fontSize: "1rem",
              lineHeight: "150%",
              letterSpacing: "0.15px",
              padding: "1rem",
              borderColor: "rgba(0,0,0,0.02)",
              "&:first-child": {
                borderTopLeftRadius: "0.625rem",
                borderBottomLeftRadius: "0.625rem",
              },
              "&:last-child": {
                borderTopRightRadius: "0.625rem",
                borderBottomRightRadius: "0.625rem",
              },
            },
            "& .MuiTableRow-root": {
              "& td": {
                padding: "14px 8px !important",
                cursor: "pointer",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                whiteSpace: "nowrap",
              },
              "&:last-child": {
                "& td": {
                  borderBottom: "0px solid white",
                },
              },
            },
          },
        },
      },

      MuiTableContainer: {
        styleOverrides: {
          root: {
            backgroundColor: "#FFF",
            marginBottom: 16,
            padding: "0rem 0rem 0.5rem 0rem",
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.16)",
            borderRadius: 16,
            "&::-webkit-scrollbar": {
              width: "8px",
              height: "15px",
              overflow: "auto",
              left: "-100px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#c1c1c1",
              borderRadius: "10px",
              border: "2px solid #f1f1f1",
            },
            "&::-webkit-scrollbar-track": {
              marginBottom: "50px",
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            // backgroundColor: isDarkMode ? 'rgb(44, 44, 44)' : 'rgb(255,255,255)',
            "&.MuiPopover-paper": {
              boxShadow: "0px 8px 40px rgba(51, 86, 116, 0.1);",
              marginTop: 3,
            },
          },
          rounded: {
            borderRadius: 24,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            minWidth: 500,
            [breakpoints.down("sm")]: {
              borderRadius: 0,
              minWidth: 300,
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: 10,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: "0.875rem",
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          margin: "dense",
          variant: "outlined",
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              "& input": {
                padding: "24px",
              },
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            paddingRight: "32px !important",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input": {
              padding: "12px 24px",
            },
          },
        },
      },
      Mui: {
        defaultProps: {
          underline: "none",
          variant: "body1",
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            minWidth: 30,
            height: 30,
            fontSize: 12,
          },
        },
      },
    },
    palette: {
      primary: {
        main: "#008C64",
        dark: "#53555E",
        light: "#3472D6",
      },
      secondary: {
        main: "#5D5DFF",
      },
      info: {
        main: "#757575",
      },
      error: {
        main: red.A400,
      },
      success: {
        light: green[600],
        main: "#10B981",
        dark: green[400],
        contrastText: "#fff",
      },
      text: {
        secondary: "rgba(0,0,0,0.7)",
      },
      background: { default: "rgba(255, 255, 255, 1)" },
    },
    typography: {
      fontFamily: ["InterRegular"].join(","),

      body2: {
        fontSize: "0.875rem",
        fontWeight: 400,
      },
      h1: {
        fontSize: "2.275rem",
        fontWeight: 600,
      },
      h2: {
        fontSize: "1.625rem",
        fontWeight: 600,
      },
      h3: {
        fontSize: "1.375rem",
        fontWeight: 600,
      },
      h4: {
        fontSize: "1.25rem",
        fontWeight: 600,
      },
      h5: {
        fontSize: "1rem",
        fontWeight: 600,
      },
      h6: {
        fontSize: "0.875rem",
        fontWeight: 500,
      },
    },
    shadows: [
      "none",
      "rgba(0, 0, 0, 0.1) 0px 1px 4px 0px",
      "rgba(0, 0, 0, 0.15) 0px 1px 4px 0px",
      "rgba(0, 0, 0, 0.15) 0px 4px 8px 0px",
      "rgba(0, 0, 0, 0.15) 0px 4px 8px 0px",
      "rgba(0, 0, 0, 0.1) 0px 8px 16px 0px",
      "rgba(0, 0, 0, 0.1) 0px 8px 24px 0px",
      "rgba(0, 0, 0, 0.15) 0px 8px 16px 0px",
      "rgba(0, 0, 0, 0.15) 0px 8px 24px 0px",
      "rgba(0, 0, 0, 0.2) 0px 8px 16px 0px",
      "rgba(0, 0, 0, 0.2) 0px 8px 24px 0px",
      "rgba(0, 0, 0, 0.2) 0px 1px 6px 2px",
      "rgba(0, 0, 0, 0.2) 0px 1px 7px 2px",
      "rgba(0, 0, 0, 0.2) 0px 1px 8px 2px",
      "rgba(0, 0, 0, 0.2) 0px 1px 9px 2px",
      "rgba(0, 0, 0, 0.2) 0px 1px 10px 0px",
      "rgba(0, 0, 0, 0.2) 0px 1px 11px 0px",
      "rgba(0, 0, 0, 0.2) 0px 1px 12px 0px",
      "rgba(0, 0, 0, 0.2) 0px 1px 13px 0px",
      "rgba(0, 0, 0, 0.2) 0px 1px 14px 0px",
      "rgba(0, 0, 0, 0.2) 0px 1px 15px 0px",
      "rgba(0, 0, 0, 0.2) 0px 1px 16px 0px",
      "rgba(0, 0, 0, 0.2) 0px 1px 17px 0px",
      "rgba(0, 0, 0, 0.2) 0px 1px 18px 0px",
      "rgba(0, 0, 0, 0.2) 0px 1px 19px 0px",
    ],
  });
};

export default theme;
