import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography, styled, useMediaQuery } from "@mui/material";
import { Parallax } from "react-parallax";
import theme from "../theme";

const Root = styled(Box)(() => ({
    minHeight: "80vh",
    "& .react-parallax-bgimage": {
        opacity: 0.4
    }
}));

const adITems = [
    "actuators",
    "air filters",
    "air heater equipment",
    "air systems",
    "antennas",
    "ball valves",
    "bearings",
    "belts",
    "brakes",
    "brushes",
    "burners",
    "butterfly valves",
    "cable equipment",
    "cables",
    "car washing equipment",
    "chains",
    "chemical plant engineering",
    "cold rolls",
    "collects",
    "compensators",
    "compressors",
    "control equipment",
    "control implements",
    "control systems",
    "control units",
    "couplings",
    "crane equipment",
    "cylinders",
    "detectors",
    "drilling machines"
];

const eiITems = [
    "electric hydraulic implements",
    "electric implements",
    "electric motors",
    "electrical valves",
    "electronic implements",
    "encoders",
    "energy chains",
    "engines",
    "fans",
    "filters",
    "fittings",
    "flanges",
    "flowmeter",
    "fuses",
    "gas controlling",
    "gas counter",
    "gas equipment",
    "gas protection equipment",
    "gaskets",
    "gauges",
    "gears",
    "glass",
    "grease machines",
    "greasing instruments",
    "grinding machines",
    "grinding material",
    "grinding wheels",
    "heating cable",
    "high pressure fittings",
    "hoist equipment",
    "hoists",
    "hoses",
    "hot rolls",
    "hydraulic implements",
    "hydraulic machines",
    "hydraulic systems",
    "interuptor",
    "isolators"
];

const lpItems = [
    "lamps",
    "low measuring systems",
    "marking machines",
    "marking systems",
    "measuring equipment",
    "measuring implements",
    "measuring instruments",
    "measuring systems",
    "mechanical implements",
    "metal implements",
    "mining machines",
    "motors",
    "nozzles",
    "ofen controller",
    "ovens",
    "packing machine",
    "painting equipment",
    "painting machines",
    "particle counter",
    "pipes",
    "plastic injection machines",
    "plastic plates",
    "plate heat exchangers",
    "plates",
    "pneumatic implements",
    "pneumatic systems",
    "pneumatic cylinders",
    "power clamps",
    "power controls",
    "power plant equipment",
    "power supplies",
    "pressure equipment",
    "pressure implements",
    "pressure switches",
    "pumps",
    "pyrometer"
];

const rzItems = [
    "registration equipment",
    "relays",
    "rollers",
    "rolls",
    "rotary joint",
    "saw blades",
    "seals",
    "sensor systems",
    "sparking plugs",
    "special implements",
    "special machines",
    "special steel",
    "special steel plant equipment",
    "special steel plant machines",
    "steel",
    "steel plant engineering",
    "switches",
    "tachogenerators",
    "temperature equipment",
    "thermoelements",
    "transducer",
    "transformators",
    "transformer",
    "transmissions & reducers",
    "tyristores",
    "vacuum implements",
    "vacuum machines",
    "valves",
    "ventilators",
    "welding equipments",
    "welding rods"
];

export const Equipment = () => {
    return (
        <Root>
            <Parallax strength={500}>
                <Box my={5} display="flex" justifyContent="center">
                    <Typography textAlign="center" fontWeight={600} variant={"h4"} color="#000" >INDUSTRIAL EQUIPMENTS AND SPARE PARTS</Typography>
                </Box>
            </Parallax>
            <Container maxWidth="md" >
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography fontWeight={600}>A - D</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {adITems.map(item => {
                            return <Typography>
                                - {item}
                            </Typography>
                        })}

                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography fontWeight={600}>E - I</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {eiITems.map(item => {
                            return <Typography>
                                - {item}
                            </Typography>
                        })}
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography fontWeight={600}>L - P</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {lpItems.map(item => {
                            return <Typography>
                                - {item}
                            </Typography>
                        })}
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography fontWeight={600}>R - Z</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {rzItems.map(item => {
                            return <Typography>
                                - {item}
                            </Typography>
                        })}
                    </AccordionDetails>
                </Accordion>

            </Container>
        </Root>
    )

}