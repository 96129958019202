import { Box, Typography, styled, useMediaQuery } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import theme from "../theme";


const Root = styled(Box)(() => ({

}));
const Footer = () => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery(theme().breakpoints.down(1192));

    return (
        <Root style={{ backgroundColor: "#0a2941", width: "100%", bottom: 0., display: "flex", justifyContent: "space-evenly", flexDirection: isMobile ? "column" : "row" }} >
            {!isMobile && <Box padding={10} display="flex" flexDirection="column" alignItems="flex-start" gap={3}>
                <Typography color="rgba(255,255,255,0.33)">IMEX</Typography>
                <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2}>
                    <Box color="rgba(255,255,255,0.59)">We create a partnership.</Box>
                </Box>
            </Box>}

            <Box padding={10} display="flex" flexDirection="column" alignItems={isMobile ? "center" : "flex-end"} gap={3}>
                <Typography color="rgba(255,255,255,0.33)">PRODUCT CATEGORY</Typography>
                <Box display="flex" flexDirection="column" alignItems={isMobile ? "center" : "flex-end"} gap={2}>
                    <Box onClick={() => navigate("/products/equipment")} style={{ cursor: "pointer", textAlign: "center" }} color="rgba(255,255,255,0.59)">Industrial Equipment and Spare parts</Box>
                    <Box onClick={() => navigate("/products/chemicals")} style={{ cursor: "pointer" }} color="rgba(255,255,255,0.59)">Chemicals and Mineral</Box>
                    <Box onClick={() => navigate("/products/emergency")} style={{ cursor: "pointer" }} color="rgba(255,255,255,0.59)">Safety and PPE</Box>
                </Box>
            </Box>
            <Box padding={10} display="flex" flexDirection="column" alignItems={isMobile ? "center" : "flex-end"} gap={3}>
                <Typography color="rgba(255,255,255,0.33)">COMPANY</Typography>
                <Box display="flex" flexDirection="column" alignItems={isMobile ? "center" : "flex-end"} gap={2}>
                    <Box style={{ cursor: "pointer" }} onClick={() => navigate("/about")} color="rgba(255,255,255,0.59)">About us</Box>
                    <Box style={{ cursor: "pointer" }} onClick={() => navigate("/products")} color="rgba(255,255,255,0.59)">Products</Box>
                    <Box style={{ cursor: "pointer" }} onClick={() => navigate("/contact")} color="rgba(255,255,255,0.59)">Contact us</Box>
                </Box>
            </Box>
        </Root>
    );
};

export default Footer;