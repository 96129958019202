import { Box, Button, Typography, styled, useMediaQuery } from "@mui/material"
import { Parallax } from "react-parallax"
import about from "../assets/about.jpg"
import theme from "../theme"
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import { useNavigate } from "react-router-dom";

const Root = styled(Box)(() => ({
    "& .react-parallax-bgimage": {
        filter: "brightness(0.5) !important"
    }
}));

export const About = () => {
    const isMobile = useMediaQuery(theme().breakpoints.down(1192));
    const navigate = useNavigate()
    return (
        <Root>
            <Parallax bgImage={about} strength={500}>
                <Box style={{ height: isMobile ? 250 : 500, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "10px" }}>
                    <Typography fontWeight={600} variant={isMobile ? "h4" : "h2"} color="#FFF" >ABOUT US</Typography>
                </Box>
            </Parallax>
            <Box style={{ backgroundColor: "#FFF", display: "flex", justifyContent: "flex-start", padding: "40px 0px", alignItems: "center", flexDirection: "column", gap: "10px" }}>
                <Typography variant="h6" color="rgba(1,22,39,0.61)" fontFamily='"Andada", lato'>HIGH QUALITY TACTICAL SOLUTIONS</Typography>
                <Typography textAlign="center" variant="h4" fontFamily='"Andada", lato'>Finding a permanent solution is our ultimate goal!</Typography>
                <Typography maxWidth="90%" textAlign="center" variant="h5" fontFamily='"Andada", lato'>We understanding the needs of the industry and submit relevant products and solutions to serve them all.</Typography>

                <Typography maxWidth="60%" mt={8} variant="h6" color="rgba(1,22,39,0.61)" fontFamily='"Andada", lato'>OUR STRATEGY</Typography>
                <Typography maxWidth="90%" textAlign="center" variant="h5" fontFamily='"Andada", lato'>Our strategy has always been to provide the highest level of trust, integrity and transparency to our supplier partners and customers. It’s a formula that has allowed us to build meaningful, trusted long-term partnership and deliver uninterrupted and promising growth.</Typography>

                <Typography maxWidth="60%" mt={8} variant="h6" color="rgba(1,22,39,0.61)" fontFamily='"Andada", lato'>OUR APPROACH</Typography>
                <Typography maxWidth="90%" textAlign="center" variant="h5" fontFamily='"Andada", lato'>We offer, add values and make a difference through expertise by employing highly technical market specialists to promote the goods from leading suppliers to customers in a wide range of market sectors. IMEX CORP builds strong and flexible relationships with suppliers seeking to simplify and upgrade supplier business operations whilst supporting their business development and providing them with valuable market intelligence within set deadlines and technical expertise. We belief that, if we value our customers’ goals and expectations as our own, our shared opportunities will be unlimited.</Typography>
            </Box>

            <Box style={{ padding: "80px 20%", backgroundColor: "#f9f8f8", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", gap: "10px" }}>
                <Box display="flex" width="100%" justifyContent="space-between" flexDirection={isMobile ? "column" : "row"} alignItems="center">
                    <Box display="flex" flexDirection="column" alignItems={isMobile ? "center" : "flex-start"} >
                        <Typography fontSize="18px" color="rgba(1,22,39,0.61)" >SOME COOL FACTS</Typography>
                        <Typography fontWeight={600} fontSize="30px" textAlign={isMobile ? "center" : "start"} >Numbers speak for <br /> themselves</Typography>
                    </Box>
                    <Box display="flex" gap={5} flexDirection={isMobile ? "column" : "row"}>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography fontWeight={600} color="rgb(10, 41, 65)" fontSize="60px" >
                                <CountUp end={240} duration={3} redraw >
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </Typography>
                            <Typography fontSize="20px"> Products</Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography fontWeight={600} color="rgb(10, 41, 65)" fontSize="60px" >
                                <CountUp end={5} duration={3} redraw>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </Typography>
                            <Typography fontSize="20px">Years of Experience</Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography fontWeight={600} color="rgb(10, 41, 65)" fontSize="60px" >
                                <CountUp end={87} duration={3} redraw >
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </Typography>
                            <Typography fontSize="20px"> Completed Projects</Typography>
                        </Box>
                    </Box>
                </Box>
                <Typography fontWeight={600} fontSize={24} mt={10} textAlign="center">Quality comes first! We provide products from trusted manufacturers. Our focus is 100% on client needs and satisfaction.</Typography>
            </Box>
            <Box style={{ padding: "50px 0px", minHeight: "200px", backgroundColor: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", gap: "20px", flexDirection: isMobile ? "column" : "row" }}>
                <Typography textAlign={isMobile ? "center" : "start"} width={isMobile ? "80%" : "60%"} fontSize="30px" fontWeight={600} color="#000" >Need help with easier industrial solutions? We are experts!</Typography>
                <Button style={{ borderRadius: "25px", padding: "10px 15px" }} onClick={() => navigate("/contact")} variant="contained">Learn more</Button>
            </Box>
        </Root >
    )

}