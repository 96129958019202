import { Box, Collapse, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import logo from "../assets/imex-corp-logo.png"
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export const menuItems = [
    { path: "/", name: "HOME" },
    { path: "/logistics", name: "LOGISTICS" },
    {
        path: "/products", name: "PRODUCTS", children: [
            { path: "/products/equipment", name: "Equipment and Spare parts" },
            { path: "/products/chemicals", name: "Chemicals" },
            { path: "/products/emergency", name: "Emergency Equipment, First Aid and PPE" }
        ]
    },
    { path: "/about", name: "ABOUT" },
    { path: "/contact", name: "CONTACT" },
]

const renderMenuItems = (items, setOpen, n, setChildOpen, childOpen) => {
    const navigate = (path) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        n(path, { replace: true })
    }
    return <Box backgroundColor="rgb(10, 41, 65)" >
        {items?.map(item => {
            return (
                <Box>
                    <Box
                        onClick={() => {
                            setOpen(false)
                            navigate(item.path)
                            setChildOpen(false)
                        }}
                        style={{ cursor: "pointer", textTransform: "uppercase", borderBottom: "1px solid #FFF", height: "50px", color: "#FFF", display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                        <Typography> {item.name}</Typography>
                        {item.children &&
                            <Box onClick={(e) => {
                                e.stopPropagation()
                                setChildOpen(open => !open)
                            }} style={{ position: "absolute", right: 2, width: "25%", display: "flex", justifyContent: "flex-end", paddingRight: "15px" }}>
                                <KeyboardArrowLeftIcon style={{ fill: "#FFF", transform: childOpen ? "rotate(90deg)" : "rotate(270deg)", transition: "all .3s" }} />
                            </Box>}

                    </Box>
                    <Collapse in={childOpen}>
                        {renderMenuItems(item.children, setOpen, navigate, setChildOpen, childOpen)}
                    </Collapse>
                </Box>
            )
        })}

    </Box>
}

const HeaderMobile = () => {
    const [open, setOpen] = useState(false)
    const [childOpen, setChildOpen] = useState(false)
    const n = useNavigate()
    const navigate = (path) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        n(path, { replace: true })
    }
    return (
        <Box>
            <Box style={{ backgroundColor: "#FFF", boxSizing: "border-box", padding: "0px 30px" }} boxShadow="0 0 6px 0 rgba(0,0,0,.12)" width="100%" height="90px" zIndex={2} position="fixed" top={0} display="flex" alignItems="center" justifyContent="space-between">
                <img onClick={() => {
                    setOpen(false)
                    navigate("/")
                }} style={{ width: "300px", height: "50px", objectFit: "cover", cursor: "pointer" }} src={logo} alt="logo" />
                <IconButton onClick={() => {
                    setOpen(prev => !prev)
                }}>
                    <MenuIcon />
                </IconButton>
            </Box>
            <Collapse style={{ position: "fixed", zIndex: 2, width: "100%" }} in={open}>
                <Box backgroundColor="rgb(10, 41, 65)" >
                    {renderMenuItems(menuItems, setOpen, navigate, setChildOpen, childOpen)}
                </Box>
            </Collapse>
        </Box>
    );
};

export default HeaderMobile;