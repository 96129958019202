import { Box, Typography, styled, useMediaQuery } from "@mui/material";
import "animate.css/animate.min.css";
import { useState } from "react";
import VisibilitySensor from 'react-visibility-sensor';
import { Pagination } from 'swiper';
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import boat from "../assets/boat.jpeg";
import plain from "../assets/plain.jpeg";
import train from "../assets/train.jpeg";
import truck from "../assets/truck.jpeg";
import locistic from "../assets/logistic.jpeg";
import ball from "../assets/video/ball.mp4";
import global from "../assets/video/global.mp4";
import time from "../assets/video/time.mp4";
import theme from "../theme";
import "./logistic.css";
import { Parallax } from "react-parallax";



const Root = styled(Box)(({ theme }) => ({
    position: "relative",
    overflow: "hidden",
    '@keyframes textAnimate': {
        '0%': {
            left: '-100%',
        },
        '100%': {
            left: '0%',
        },

    },
    '@keyframes textAnimateRight': {
        '0%': {
            right: '-100%',
        },
        '100%': {
            right: '0%',
        },

    },
    '& .text': {
        animation: 'textAnimate 1s',
        position: 'relative',
        "-webkit-text-stroke-width": "3px",
        "-webkit-text-stroke-color": "rgb(10, 41, 65)",
        color: "#FFF",
        letterSpacing: "3px",
        lineHeight: "90px",
        fontWeight: 700,
        fontSize: "100px"
    },
    '& .text-bottom': {
        animation: 'textAnimate 1s',
        position: 'relative',
        color: "rgb(10, 41, 65)",
        letterSpacing: "3px",
        lineHeight: "90px",
        fontWeight: 700,
        fontSize: "100px"
    },
    "& .swiper-slide img": {
        height: "500px"
    },
    "& .autoplay-progress svg": {
        stroke: "#FFF !important",
        color: "#FFF !important"
    },
    '& .text-right': {
        animation: 'textAnimateRight 1s',
        position: 'relative',
        "-webkit-text-stroke-width": "3px",
        "-webkit-text-stroke-color": "rgb(10, 41, 65)",
        color: "#FFF",
        lineHeight: "90px",
        letterSpacing: "3px",
        fontWeight: 700,
        fontSize: "100px"
    },
    '& .text-right-covered': {
        animation: 'textAnimateRight 1s',
        position: 'relative',
        lineHeight: "90px",
        letterSpacing: "3px",
        fontWeight: 700,
        fontSize: "100px"
    },
    "& .d-none": {
        visibility: "hidden"
    },
    "& .react-parallax-bgimage": {
        filter: "brightness(0.9) !important"
    },
    [theme.breakpoints.down(992)]: {
        "& .text": {
            fontSize: "50px",
            lineHeight: "50px"
        },
        "& .text-bottom": {
            fontSize: "50px",
            lineHeight: "50px"
        },
        "& .text-right": {
            fontSize: "50px",
            lineHeight: "50px"
        },

        "& .text-right-covered": {
            fontSize: "50px",
            lineHeight: "50px"
        },

        '& .text-container': {
            marginTop: "50px",
            "& h2": {
                fontWeight: 800
            }
        },
        "& .swiper-slide img": {
            height: "400px"
        },
    },



}));

export const Logistics = () => {
    const isMobile = useMediaQuery(theme().breakpoints.down(1192));
    const [show, setShow] = useState(false)


    return (
        <Root>
            <Parallax style={{ padding: "50px 0px", height: "500px" }} bgImage={locistic} strength={500}>
                <Box className='text-container'>
                    <Typography textAlign="center" className="text" variant="h2">PUTTING</Typography>
                    <Typography textAlign="center" className="text-right" variant="h2">THE LOGIC</Typography>
                    <Typography textAlign="center" className="text" variant="h2">INTO LOGISTICS</Typography>
                    <Typography color="#FFF" textAlign="center" mt={3} padding={isMobile ? "0 5%" : "0 28%"}>Whether by land, sea or air our collective years of experience and cutting-edge technology ensure that whatever you need delivered will get there in the most timely, efficient and cost-effective method possible.</Typography>
                </Box>
            </Parallax>
            <Box padding={isMobile ? "10px" : "100px"}>
                <Box display="flex" alignItems="flex-start" justifyContent="center" flexDirection={isMobile ? "column" : "row"} gap={3} mt={12}>
                    <Box flex={1} style={{ backgroundColor: "#F4F4F4" }} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <video style={{ margin: "20px 60px 10px 60px" }} width={200} autoPlay muted loop playsInline src={global}></video>
                        <Box width="100%" mb={10}>
                            <Typography textTransform="uppercase" variant="h5" fontWeight={700} textAlign="center" fontFamily='"Montserrat", Sans-serif' >We are truly global!</Typography>
                            <Typography padding="0px 30px" fontFamily='"Montserrat", Sans-serif' fontSize="16px" lineHeight="1.6em" textAlign="center">From Asia, Europe or Middle East,  we can get it there with same attention to detail whilst offering you the best possible solutions.</Typography>
                        </Box>
                    </Box>
                    <Box flex={1} style={{ backgroundColor: "#F4F4F4" }} mt={isMobile ? 0 : 3} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <video style={{ margin: "20px 60px 10px 60px" }} width={200} autoPlay muted loop playsInline src={ball}></video>
                        <Box width="100%" mb={10}>
                            <Typography textTransform="uppercase" variant="h5" fontWeight={700} textAlign="center" fontFamily='"Montserrat", Sans-serif' >SAFE AND SECURE.</Typography>
                            <Typography padding="0px 30px" fontFamily='"Montserrat", Sans-serif' fontSize="16px" lineHeight="1.6em" textAlign="center">From the moment we collect to the final point of delivery you’re in safe hands. You have enough on your mind running your business without worrying about your consignments.</Typography>
                        </Box>
                    </Box>
                    <Box flex={1} style={{ backgroundColor: "#F4F4F4" }} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <video style={{ margin: "20px 60px 10px 60px" }} width={200} autoPlay muted loop playsInline src={time}></video>
                        <Box width="100%" mb={10}>
                            <Typography textTransform="uppercase" variant="h5" fontWeight={700} textAlign="center" fontFamily='"Montserrat", Sans-serif' >TIMING IS EVERYTHING.</Typography>
                            <Typography padding="0px 30px" fontFamily='"Montserrat", Sans-serif' fontSize="16px" lineHeight="1.6em" textAlign="center">Your timetable is our timetable. You tell us when you want it delivered and leave the rest to us.</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box mt={isMobile ? 10 : 20}>
                    <VisibilitySensor delayedCall>
                        {({ isVisible }) => {
                            if (isVisible) {
                                setShow(true)
                            }
                            return <Typography marginLeft={isMobile ? 0 : 25} className={show ? "text-bottom" : "d-none"} fontFamily='"Montserrat", Sans-serif' variant="h2">WE</Typography>
                        }
                        }
                    </VisibilitySensor>
                    <VisibilitySensor delayedCall>
                        {({ isVisible }) => {
                            if (isVisible) {
                                setShow(true)
                            }
                            return <Typography marginLeft={isMobile ? 0 : 30} className={show ? "text-bottom" : "d-none"} fontFamily='"Montserrat", Sans-serif' variant="h2">HAVE</Typography>
                        }
                        }
                    </VisibilitySensor>
                    <Typography marginLeft={isMobile ? 0 : 35} className={show ? "text-bottom" : "d-none"} color="#5b94f0 !important" fontSize={isMobile ? "80px !important" : "145px !important"} fontFamily='"Montserrat", Sans-serif' variant="h2">YOU</Typography>
                    <VisibilitySensor delayedCall>
                        {({ isVisible }) => {
                            if (isVisible) {
                                setShow(true)
                            }
                            return <Typography marginLeft={isMobile ? 0 : 40} className={show ? "text-right-covered" : "d-none"} color="rgb(10, 41, 65)" fontFamily='"Montserrat", Sans-serif' variant="h2" > COVERED</Typography>
                        }
                        }
                    </VisibilitySensor>
                    <Typography maxWidth={isMobile ? "100%" : "40%"} mt={3} ml={isMobile ? 0 : 25} >Whatever the size of shipment, or preferred transportation we have the perfect solution. Need assistance with customs? </Typography>

                </Box>
                <Swiper
                    slidesPerView={isMobile ? 1 : 3}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                    style={{ margin: "100px 0px" }}
                    paginationStyle={{ bottom: "500px !important" }}
                >
                    <SwiperSlide><Box position="relative"><img style={{ filter: "brightness(80%)" }} src={train} alt="train" /> <Typography color="#FFF" fontSize="25px" fontWeight={700} position="absolute" top={40} left={40}>TRAIN FREIGHT</Typography></Box></SwiperSlide>
                    <SwiperSlide><Box position="relative"><img style={{ filter: "brightness(80%)" }} src={truck} alt="truck" /> <Typography color="#FFF" fontSize="25px" fontWeight={700} position="absolute" top={40} left={40}>ROAD FREIGHT</Typography></Box></SwiperSlide>
                    <SwiperSlide><Box position="relative"><img style={{ filter: "brightness(80%)" }} src={plain} alt="plain" /> <Typography color="#FFF" fontSize="25px" fontWeight={700} position="absolute" top={40} left={40}>AIR FREIGHT</Typography></Box></SwiperSlide>
                    <SwiperSlide><Box position="relative"><img style={{ filter: "brightness(80%)" }} src={boat} alt="boat" /> <Typography color="#FFF" fontSize="25px" fontWeight={700} position="absolute" top={40} left={40}>SEA FREIGHT</Typography></Box></SwiperSlide>
                </Swiper>
            </Box>

        </Root >
    )

}