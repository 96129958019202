import EmailIcon from '@mui/icons-material/Email'
import { Box, Button, Typography, styled, useMediaQuery } from "@mui/material"
import { Parallax } from "react-parallax"
import products from "../assets/products.jpg"
import theme from "../theme"
import { useNavigate } from 'react-router-dom'

const Root = styled(Box)(() => ({
    "& .react-parallax-bgimage": {
        filter: "brightness(0.5) !important"
    }
}));

export const Products = () => {
    const isMobile = useMediaQuery(theme().breakpoints.down(1192));
    const navigate = useNavigate()
    return (
        <Root>
            <Parallax bgImage={products} strength={500}>
                <Box style={{ height: isMobile ? 250 : 500, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "10px" }}>
                    <Typography fontWeight={600} variant={isMobile ? "h4" : "h2"} color="#FFF" >PRODUCTS</Typography>
                </Box>
            </Parallax>
            <Box style={{ backgroundColor: "#FFF", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", gap: "10px", padding: isMobile ? "50px" : "50px 150px" }}>
                <Typography fontWeight={400} color="rgba(1,22,39,0.61)">OPTIMIZED & INDUSTRY-READY SOLUTIONS</Typography>
                <Box mt={1} display="flex" flexDirection="column" gap={2}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Typography fontWeight={600} fontSize="30px">For all your complicated needs</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Typography fontWeight={600} width={isMobile ? "100%" : "40%"} color="rgba(1,22,39,0.75)" fontSize="16px">We support your industrial production, equipment, and processes with our diverse products across global operations and supply chains.</Typography>
                    </Box>

                </Box>
            </Box>
            <Box style={{ height: "300px", backgroundColor: "#f9f8f8", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "10px" }}>
                <Typography variant="h6" color="rgba(1,22,39,0.61)" fontFamily='"Andada", lato'>OUR INNOVATIONS</Typography>
                <Typography textAlign="center" variant="h4" color="rgba(1,22,39,0.61)" fontFamily='"Andada", lato'>Industry Focused Products!</Typography>
            </Box>
            <Box style={{ padding: "50px 0px", minHeight: "200px", backgroundColor: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", gap: "20px", flexDirection: isMobile ? "column" : "row" }}>
                <Typography textAlign={isMobile ? "center" : "start"} width={isMobile ? "80%" : "60%"} fontSize="30px" fontWeight={600} color="#000" >Need help with easier industrial solutions? We are experts!</Typography>
                <Button style={{ borderRadius: "25px", padding: "10px 15px" }} onClick={() => navigate("/contact")} variant="contained">Learn more</Button>
            </Box>
        </Root>
    )

}