import { Box, styled } from '@mui/material';
import React from 'react';
import logo from "../assets/imex-corp-logo.png";
import { useNavigate } from 'react-router-dom';

export const menuItems = [
    { path: "/", name: "HOME" },
    { path: "/logistics", name: "LOGISTICS" },
    {
        path: "/products", name: "PRODUCTS", children: [
            { path: "/products/equipment", name: "Equipment and Spare parts" },
            { path: "/products/chemicals", name: "Chemicals" },
            { path: "/products/emergency", name: "Emergency Equipment, First Aid and PPE" }
        ]
    },
    { path: "/about", name: "ABOUT" },
    { path: "/contact", name: "CONTACT" },
]

const Root = styled(Box)(() => ({
    "& .selectedItem": {
        backgroundColor: "#f3f3f3",
        color: "#324ea8",
    },
    "& .menuItem": {
        transition: "all .4s",
        userSelect: "none",
        "&:hover": {
            backgroundColor: "#f3f3f3",
            color: "#324ea8",
            "& + .children": {
                display: "block",
                transition: "all 3s"
            }
        }
    },
    "& .children": {
        position: "absolute",
        top: "90px",
        left: "0px",
        width: "200%",
        backgroundColor: "#f3f3f3",
        transition: "all 3s",
        "&:hover": {
            display: "block",
            transition: "all 3s"
        }
    },
    "& .child": {
        color: "#324ea8",
        "&:hover": {
            backgroundColor: "#FFF"
        }
    }
}));
const Header = () => {
    const n = useNavigate()

    const navigate = (path) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        n(path, { replace: true })
    }
    return (
        <Root style={{ backgroundColor: "#FFF", boxSizing: "border-box", padding: "0px 200px" }} boxShadow="0 0 6px 0 rgba(0,0,0,.12)" width="100%" height="90px" zIndex={2} position="fixed" top={0} display="flex" alignItems="center" justifyContent="space-between">
            <img onClick={() => navigate("/")} style={{ width: "300px", height: "50px", objectFit: "cover", cursor: "pointer" }} src={logo} alt="logo" />
            <Box display="flex" gap={2} height="100%" alignItems="center">
                {menuItems.map(item => {
                    return (
                        <Box position="relative" height="100%">
                            <Box
                                className={window.location.href.includes(item.path) && item.path !== "/" ? "selectedItem menuItem" : window.location.pathname === "/" && item.path === "/" ? "selectedItem menuItem" : "menuItem"}
                                onClick={() => {
                                    navigate(item.path)
                                }}
                                height="100%"
                                display="flex"
                                alignItems="center"
                                padding="0px 20px"
                                style={{ cursor: "pointer" }}
                            >{item.name}</Box>
                            {item.children && <Box className="children" position="absolute" display="none" top={0}>
                                {item.children.map(child => {
                                    return <Box onClick={() => navigate(child.path)} className="child" style={{ padding: "10px", cursor: "pointer" }}>{child.name}</Box>
                                })}
                            </Box>}
                        </Box>
                    )
                })}
            </Box>
        </Root>
    );
};

export default Header;