import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography, styled, useMediaQuery } from "@mui/material";
import { Parallax } from "react-parallax";
import theme from "../theme";

const Root = styled(Box)(() => ({
    minHeight: "80vh",
    "& .react-parallax-bgimage": {
        opacity: 0.4
    }
}));

const emergencyItems = [
    "AEDs & Accessories",
    "Bleeding Control",
    "Breathing and Respiratory Equipment",
    "Defibrillators",
    "Diabetic Medical Supplies",
    "Diagnostic Medical Equipment",
    "Emergency Medical Service Clothing and Accessories",
    "First Aid Kits",
    "Fire and Rescue Equipment",
    "Immobilization and Extrication Equipment",
    "Infection Control Products",
    "IV Therapy Supplies",
    "Medical Education and Training Equipment",
    "Medical Equipment Bags and Cases",
    "Over-The-Counter Pharmaceuticals",
    "Patient Transport Equipment",
    "Pharmacy Medical Prescription Pharmaceuticals",
    "Pink Products for Breast Cancer Awareness",
    "Re-Certified Defibrillators",
    "Tactical Medicine",
    "Wound Care Treatment Supplies"
];

const physicianItems = [
    "Infection Control Supplies",
    "Intravenous Therapy Merchandise",
    "Orthopedic Supplies",
    "Over-The-Counter Pharmaceuticals",
    "Pharmacy Medical Prescription Pharmaceuticals",
    "Re-certified AEDs",
    "Re-Certified Defibrillators",
    "Surgery Center & Urgent Care",
    "Surgical Procedure Instruments and Supplies",
    "Wound Care Healing Management Supplies"
];

const teachingItems = [
    "Defibrillation Training Equipment",
    "Human Anatomical Models",
    "Medical Specialty Trainers and Simulators",
    "Re-certified AEDs",
    "Training Kits",
    "Training Manikins"
];

const fasItems = [
    "Adhesive Medical Tape",
    "Active Shooter Kits",
    "Alcohol Prep Pads",
    "Emergency Eye Care",
    "First Aid Bandages",
    "First Aid Burn Care",
    "First Aid Kits",
    "General Wound Care Treatment",
    "Hot and Cold Packs",
    "Medical Gauze",
    "Medical Splints",
    "Orthopedic Supports",
    "Personal Cleansing Wipes",
    "Pink Products for Breast Cancer Awareness",
    "Staple & Suture Removal Kits",
    "Trauma Wound Care",
    "Wound Closure Strips",
    "Wound Closure Trays"
];

const fallItems = [
    "Anchors, Straps & Slings",
    "Ascenders, Descenders, Fall Limiters",
    "Confined Space Equipment",
    "Fall Protection Kits",
    "Harnesses",
    "Lanyards",
    "Lifeline Systems",
    "Ropes & Rope Grabs"
];

const headItems = [
    "Bump Caps",
    "Faceshields and Headgear",
    "Hard Hat Accessories",
    "Hard Hats",
    "Liners, Heating & Cooling",
    "Welding Helmets",
    "Earmuffs",
    "Earplug Dispensers",
    "Earplugs",
    "Hearing Bands",
    "Hearing Protection Cleaning Products"
];

const speItems = [
    "Eyewear Accessories",
    "Eyewear Dispensers",
    "Lens Cleaners",
    "Magnifying & Bifocal Safety Glasses",
    "Safety Glasses",
    "Safety Goggles"
];

const spgItems = [
    "Anti-Vibration Gloves",
    "Chemical-Resistant Gloves",
    "Disposable Gloves",
    "Glove Dispensers & Accessories",
    "Kevlar Gloves & Cut-Resistant Gloves",
    "Leather Work Gloves",
    "Mechanics Gloves",
    "Welding Gloves & Heat-Resistant Gloves",
    "Work Gloves & General Purpose Gloves"
];

const protectiveItems = [
    "Disposable Coveralls",
    "Flame-Resistant Coveralls and Overalls",
    "Flame-Resistant Jackets",
    "Footwear",
    "Hair Caps",
    "Lab Coats and Pants",
    "Protective Clothing Accessories & Storage",
    "Protective Sleeves",
    "Rainwear",
    "Safety Vests",
    "Shoe Covers",
    "Welding, Flame-Resistant, and Protective Aprons"
];







export const Emergency = () => {
    return (
        <Root>
            <Parallax strength={500}>
                <Box my={5} display="flex" justifyContent="center">
                    <Typography textAlign="center" fontWeight={600} variant={"h4"} color="#000" >Emergency Equipment, First Aid and PPE</Typography>
                </Box>
            </Parallax>
            <Container maxWidth="md" >
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography fontWeight={600}>Emergency Medical Equipment</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {emergencyItems.map(item => {
                            return <Typography>
                                - {item}
                            </Typography>
                        })}

                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography fontWeight={600}>Physician Office Supplies</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {physicianItems.map(item => {
                            return <Typography>
                                - {item}
                            </Typography>
                        })}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography fontWeight={600}>Teaching and Training Supplies</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {teachingItems.map(item => {
                            return <Typography>
                                - {item}
                            </Typography>
                        })}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography fontWeight={600}>First Aid Supplies</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {fasItems.map(item => {
                            return <Typography>
                                - {item}
                            </Typography>
                        })}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography fontWeight={600}>FALL PROTECTION</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {fallItems.map(item => {
                            return <Typography>
                                - {item}
                            </Typography>
                        })}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography fontWeight={600}>Head & Hearing Protection</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {headItems.map(item => {
                            return <Typography>
                                - {item}
                            </Typography>
                        })}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography fontWeight={600}>Safety & Protective Eyewear</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {speItems.map(item => {
                            return <Typography>
                                - {item}
                            </Typography>
                        })}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography fontWeight={600}>Safety & Protective Gloves</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {spgItems.map(item => {
                            return <Typography>
                                - {item}
                            </Typography>
                        })}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography fontWeight={600}>Protective Clothing</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {protectiveItems.map(item => {
                            return <Typography>
                                - {item}
                            </Typography>
                        })}
                    </AccordionDetails>
                </Accordion>

            </Container>
        </Root>
    )

}