import { Box, useMediaQuery } from "@mui/material";
import {
  Route,
  Routes,
  BrowserRouter,
  ScrollRestoration,
} from "react-router-dom";
import "./App.css";
import Header from "./components/header";
import HeaderMobile from "./components/header-mobile";
import { Home } from "./pages/home";
import theme from "./theme";
import Footer from "./components/footer";
import ScrollToTop from "./components/scroll-to-top";
import { Contact } from "./pages/contact";
import { About } from "./pages/about";
import { Products } from "./pages/products";
import { Equipment } from "./pages/equipment";
import { Chemicals } from "./pages/chemicals";
import { Emergency } from "./pages/emergency";
import { Logistics } from "./pages/logistics";

function App() {
  const isMobile = useMediaQuery(theme().breakpoints.down(1192));
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Box paddingTop="90px" minHeight="calc(100vh - 300px)">
        {isMobile ? <HeaderMobile /> : <Header />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/logistics" element={<Logistics />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/equipment" element={<Equipment />} />
          <Route path="/products/chemicals" element={<Chemicals />} />
          <Route path="/products/emergency" element={<Emergency />} />
        </Routes>
      </Box>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
